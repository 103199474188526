import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const HeroBackground = ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hero-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  const imageData = [
    `linear-gradient(to bottom right, #00adef80, #ed1c2480)`,
    desktop.childImageSharp.fluid,
  ]

  return (
    <BackgroundImage
      className={className}
      fluid={imageData}
      loading="eager"
      durationFadeIn={25}
    >
      {children}
    </BackgroundImage>
  )
}

const Hero = () => (
  <HeroBackground className="hero">
    <div className="hero__text">
      <h1>Najwyższej jakości urządzenia</h1>
      <p>
        <strong>u autoryzowanego dystrybutora Alfa&nbsp;Laval</strong>
      </p>
    </div>
  </HeroBackground>
)

export default Hero
