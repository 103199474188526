import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const About = ({ children }) => {
  const { authorizedDistributor } = useStaticQuery(
    graphql`
      query {
        authorizedDistributor: file(relativePath: { eq: "authdist.png" }) {
          childImageSharp {
            fluid(maxWidth: 447, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <div className="about">
      <div className="about__text">
        <Img
          imgStyle={{
            width: "40vw",
            maxWidth: "9rem",
            padding: "0 0 1rem 1rem",
            float: "right",
          }}
          className="about__image"
          fluid={authorizedDistributor.childImageSharp.fluid || {}}
          alt="Autoryzowany dystrybutor Alfa Laval"
        />
        {children}
      </div>
    </div>
  )
}

export default About
