import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Container from "../components/Container/Container"
import Hero from "../components/Hero/Hero"
import About from "../components/About/About"
import Products from "../components/Products/Products"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Strona główna" />
    <Hero />
    <Container>
      <About>
        <p>
          <strong>EQTHERM</strong> to autoryzowany dystrybutor wymienników
          ciepła Alfa Laval. Specjalizujemy się w przemyśle ciepłowniczym,
          chłodniczym i odzysku ciepła. Naszą misją jest dostarczanie najwyższej
          jakości urządzeń w konkurencyjnych cenach.
        </p>
        <p>
          Wierzymy, że nasza dbałość o optymalny dobór techniczny urządzeń w
          połączeniu z wysoką jakością oferowanych produktów poskutkuje
          wieloletnim, efektywnym, łatwym w eksploatacji i przyjaznym dla
          środowiska działaniem.
        </p>
      </About>
      <Products>
        <p>
          Rozwiązania Alfa Laval promują nowoczesne i energooszczędne metody
          wykorzystania energii, bez względu na rodzaj źródła zasilania.
          Wykorzystując odzysk ciepła, m.in. z procesów produkcyjnych do
          ogrzewania budynków, Alfa Laval umożliwia oszczędzanie energii i
          zmniejszenie kosztów. Wymienniki ciepła Alfa Laval z certyfikatem
          wydajności zapewniają niezawodne działanie.
        </p>
      </Products>
    </Container>
  </Layout>
)

export default IndexPage
